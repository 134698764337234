<template lang="pug">
a.sbbol-btn(:href="url")
  .sbbol-btn__img
  .sbbol-btn__text Войти по СберБизнес ID
</template>

<script>
  export default {
    props: {
      baseUrl: { type: String, default: `${process.env.VUE_APP_API_HOST}/api/v2/oauth/sbbol` },
      callbackUrl: {
        type: String,
        default: `${process.env.VUE_APP_HOST}/oauth/sbbol/callback`,
      },
    },

    computed: {
      url() {
        return `${this.baseUrl}?callback_url=${this.callbackUrl}`;
      },
    },
  };
</script>

<style lang="scss">
  $sbbol_logo: #107f8c;
  $sbbol_border--hover: #21a038;
  $sbbol_border: #cacccf;

  .sbbol-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 0;
    transition: border-color $a-transition;
    border: 1px solid $sbbol_border;
    border-radius: 8px;
    background-color: c('white', 'light');
    text-align: center;
    text-decoration: none;

    .sbbol-btn__img {
      display: block;
      @include svg-bg-mask-recolored('sberbank-logo-22', $sbbol_logo, 'assets/logos');
      content: '';
      width: 22px;
      height: 22px;
    }

    .sbbol-btn__text {
      margin-left: 8px;
      color: c('black', 'light');
    }

    &:hover {
      transition: border-color $a-transition;
      border-color: $sbbol_border--hover;
    }
  }
</style>
