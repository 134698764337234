<template lang="pug">
app-layout
  v-app.layout-full
    app-nav-drawer(v-model="navDrawer")
    app-header(no-buttons @nav:click="navDrawer = true")

    v-main
      .page-sign-up.pa-6.pb-8
        a.link--no-underline.d-flex.align-center.c-primary.px-0.mb-4(:href="$path.signIn()")
          ui-icon(name="arrow-left" left)
          a.t-caption-middle Назад

        .t-h1-mobile Регистрация
        .t-subtitle.c-gray80.mt-1 Выберите подходящий вам тип профиля

        router-link.rega-btn.mt-5(:to="$path.signUpIndividual()")
          span Физическое лицо

        router-link.rega-btn.rega-btn--legal(:to="$path.signUpLegal()")
          span.pt-2 Юридическое лицо
            span.rega-btn__span Бизнес-аккаунт

        p.paragraph-with-border
          span или

        sber-id-btn.mt-3
        sber-business-id-btn.mt-3
        esia-btn.mt-3
    app-footer(no-banner)
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import AppNavDrawer from '@/components/app/AppNavDrawer';
  import AppHeader from '@/components/app/AppHeader';
  import AppFooter from '@/components/app/AppFooter';

  import { UiBtn, UiIcon } from '@/uikit';
  import SberIdBtn from '@/components/auth/SberIdBtn.vue';
  import SberBusinessIdBtn from '@/components/auth/SberBusinessIdBtn.vue';
  import EsiaBtn from '@/components/auth/EsiaBtn.vue';

  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageSignUp', 'blue');

  export default {
    name: 'page-sign-up',
    components: {
      AppLayout,
      AppNavDrawer,
      AppHeader,
      AppFooter,
      UiBtn,
      UiIcon,
      SberIdBtn,
      SberBusinessIdBtn,
      EsiaBtn,
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.prevPath = from.path;
      });
    },

    data: () => ({
      prevPath: null,
      navDrawer: false,
      loading: false,
    }),
  };
</script>

<style lang="scss">
  .page-sign-up {
    background-color: c('white', 'light');

    .paragraph-with-border {
      @include text--small;

      position: relative;
      color: c('text--dimmed', 'light');
      text-align: center;

      &:not(:last-child) {
        margin: 16px 0;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: c('black40', 'light');
      }

      span {
        position: relative;
        padding: 0 0.7em;
        background-color: c('white', 'light');
      }
    }

    .rega-btn {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      padding: 2.1em 0 2.1em 5.5em;
      border: 1px solid c('black10', 'light');
      border-radius: 8px;
      color: c('black80', 'light');
      text-align: left;
      text-decoration: none;

      &:not(:last-child) {
        margin-bottom: 0.45em;
      }

      &:hover {
        border-color: transparent;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
        color: c('primary', 'light');

        &::after {
          background-color: c('primary', 'light');
        }
      }

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        content: url('~@/assets/avatar-user.svg');
        left: 22px;
        width: 48px;
        height: 48px;
      }

      &::after {
        @include svg-bg-mask-recolored('chevron', c('gray70', 'light'), light);
        content: '';
        right: 24px;
        width: 24px;
        height: 24px;
      }

      &--legal {
        padding-top: 1.6em;

        &::before {
          content: url('~@/assets/avatar-company.svg');
        }
      }

      &__span {
        display: block;
        font-size: 13px;
      }
    }
  }
</style>
