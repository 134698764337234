<template lang="pug">
a.esia-btn(:href="url")
  .esia-btn__img
  .esia-btn__text Войти через Госуслуги
</template>

<script>
  export default {
    props: {
      baseUrl: {
        type: String,
        default: `${process.env.VUE_APP_API_HOST}/api/v2/oauth/esia`
      },
      callbackUrl: {
        type: String,
        default: `${process.env.VUE_APP_HOST}/oauth/esia/callback`,
      },
    },

    computed: {
      url() {
        return `${this.baseUrl}?callback_url=${this.callbackUrl}`;
      },
    },
  };
</script>

<style lang="scss">
  $esia_border--hover: #0d4cd3;
  $esia_border: #cacccf;

  .esia-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 0;
    transition: border-color $a-transition;
    border: 1px solid $esia_border;
    border-radius: 8px;
    background-color: c('white', 'light');
    text-align: center;
    text-decoration: none;

    .esia-btn__img {
      display: block;
      @include svg-bg-colored('esia-logo', 'assets/logos');
      content: '';
      width: 24px;
      height: 24px;
    }

    .esia-btn__text {
      margin-left: 8px;
      color: c('black', 'light');
    }

    &:hover {
      transition: border-color $a-transition;
      border-color: $esia_border--hover;
    }
  }
</style>
